<template>
  <div>
    <NavigationBar titel="Abrechnungszettel" isPreview></NavigationBar>
    <TippCard
      hintID="Abrechnung_Abrechnungszettel_1_V1"
      text="Ausgehend von Abrechnungsstapeln werden personenbezogen Abrechnungszettel angelegt. In diesen sind alle abrechnungsrelevanten Informationen für die jeweilige Person im entsprechenden Zeitraum zusammengeführt."
    >
    </TippCard>
    <section>
      <template>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-invoice-list" title="Abrechnungszettel">
              </Subheader>
              <div class="mt-7">
                <payslips-data-table
                  :payslips="payslips"
                ></payslips-data-table></div
            ></v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import PayslipsDataTable from "@/components/accounting/payslips/PayslipsDataTable.vue";
import { ACCOUNTING_PAYSLIPS_read_all } from "@/data/permission-types.js";
import { ACCOUNTING } from "@/store/modules.js";
import { GET_PAYSLIPS } from "@/store/action-types.js";
export default {
  name: "accounting-payslips",
  components: {
    NavigationBar,
    Subheader,
    TippCard,
    SupportTools,
    PayslipsDataTable,
  },
  data() {
    return {};
  },
  computed: {
    payslips() {
      return this.$store.state.accounting.payslips;
    },
    showAllPayslips() {
      return this.$store.getters["organization/checkPermissionByID"](
        `${ACCOUNTING_PAYSLIPS_read_all}`
      );
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "payslips", data: this.payslips }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      var allowedToReadAllPayslips = this.$store.getters[
        "organization/checkPermissionByID"
      ](`${ACCOUNTING_PAYSLIPS_read_all}`);
      if (allowedToReadAllPayslips) {
        this.$store.dispatch(`${ACCOUNTING}${GET_PAYSLIPS}`, {
          organizationId: this.$route.params.organizationId,
          query: {
            queryIsFiltered: false,
          },
        });
      } else {
        this.$store.dispatch(`${ACCOUNTING}${GET_PAYSLIPS}`, {
          organizationId: this.$route.params.organizationId,
          query: {
            queryIsFiltered: true,
            onlyOwn: true,
          },
        });
      }
    },
  },
};
</script>
